<script setup>
  import { ref, computed } from 'vue';
  import { Link, usePage } from '@inertiajs/vue3';
  import MainButton from '@/Components/MainButton.vue';
  import SecondaryButton from '@/Components/SecondaryButton.vue';
  import LogoSVG from '@/Components/LogoSVG.vue';
  import LogoSVGWhite from '../LogoSVGWhite.vue';
  import NavbarToggleButton from '@/Components/Navs/NavbarToggleButton.vue';
  import DropdownComponent from '../DropdownComponent.vue';
  import DropdownItem from '../DropdownItem.vue';

  const isMobileNavOpen = ref(false);

  const isLoggedIn = ref(false);
  // Access Inertia's page props
  const { url } = usePage();

  const textColorClass = computed(() => {
    if (
      url !== '/welcome-page' &&
      url !== '/welcome-page#collaborate' &&
      url !== '/' &&
      url !== '/about' &&
      url !== '/visitor-profiles-page' &&
      url !== '/visitor-gigs-page' &&
      url !== '/visitor-gig-detail' &&
      !url.startsWith('/visitor-profile-detail/') &&
      !url.startsWith('/visitor-gig-detail/')
    ) {
      return '!text-black';
    } else {
      return '!text-white hover:!text-white border-white';
    }
  });

  const currentLogoComponent = computed(() => {
    if (
      url === '/welcome-page' ||
      url === '/welcome-page#collaborate' ||
      url === '/' ||
      url === '/about' ||
      url === '/visitor-profiles-page' ||
      url === '/visitor-gigs-page' ||
      url === '/visitor-gig-detail' ||
      url.startsWith('/visitor-profile-detail/') ||
      url.startsWith('/visitor-gig-detail/')
    ) {
      return LogoSVGWhite;
    } else {
      return LogoSVG;
    }
  });

  const navLinksForVisitors = [
    { text: 'About Us', href: '/about' },
    { text: 'Profiles', href: '/visitor-profiles-page' },
    { text: 'Gigs', href: '/visitor-gigs-page' },
    { text: 'Messaging', href: '/waitlist?p=messaging' }
    // { text: 'Registration', href: '/waitlist' }
    // { text: 'Login', href: '/login' }
  ];
  // TODO: ADD REAL LINKS:
  const navLinksForUsers = [
    { text: 'Gigs Hub', href: '/user/gigs', disabled: !isLoggedIn.value },
    { text: 'All gigs', href: '/gigs', disabled: !isLoggedIn.value },
    {
      text: 'Create Profile',
      href: '/show-profile',
      disabled: !isLoggedIn.value
    },
    { text: 'Manage Calendar', href: '/#', disabled: !isLoggedIn.value },
    { text: 'Events', href: '/#', disabled: !isLoggedIn.value }
  ];
</script>

<template>
  <div
    class="absolute z-20 flex h-20 w-full max-w-6xl flex-row items-center justify-between px-2"
    style="left: 50%; transform: translateX(-50%); z-index: 99999"
  >
    <div class="w-1/4">
      <Link :href="'/'" class="flex items-center gap-2">
        <!-- Spicygigs Logo -->
        <div class="w-1/5">
          <img
            src="/images/spicy-gigs-logo-transparent.png"
            alt="Spicygigs Logo"
            class="object-contain"
          />
        </div>
        <!-- LogoSVG or dynamic component -->
        <component :is="currentLogoComponent" class="w-4/5" />
      </Link>
    </div>

    <div
      class="hidden w-1/2 flex-row justify-around space-x-4 text-base md:flex"
    >
      <Link :href="'/'" class="hover:underline" :class="textColorClass">
        Home
      </Link>
      <Link :href="'/about'" class="hover:underline" :class="textColorClass">
        About Us
      </Link>
      <DropdownComponent :top="'30px'" :left="'-50px'" :class="textColorClass">
        <template v-slot:button>For visitors</template>
        <template v-slot:content>
          <div v-for="(link, index) in navLinksForVisitors" :key="index">
            <DropdownItem
              :href="link.href"
              class="!text-gray-200 hover:!text-white hover:underline"
            >
              {{ link.text }}
            </DropdownItem>
          </div>
          <DropdownItem
            class="!text-gray-200 hover:!text-white hover:underline"
            href="/waitlist"
          >
            Register
          </DropdownItem>
          <DropdownItem
            class="!text-gray-200 hover:!text-white hover:underline"
            href="/login"
          >
            Login
          </DropdownItem>
        </template>
      </DropdownComponent>
      <DropdownComponent :top="'30px'" :left="'-50px'" :class="textColorClass">
        <template v-slot:button>For Users</template>
        <template v-slot:content>
          <div v-for="(link, index) in navLinksForUsers" :key="index">
            <DropdownItem
              :href="!link.disabled ? link.href : null"
              :class="[
                {
                  'pointer-events-none text-gray-200 opacity-65': link.disabled
                },
                '!text-gray-200 hover:underline'
              ]"
            >
              {{ link.text }}
            </DropdownItem>
          </div>
        </template>
      </DropdownComponent>
    </div>

    <NavbarToggleButton
      @click="isMobileNavOpen = !isMobileNavOpen"
      class="right-0 justify-end text-primary hover:text-primary-darker md:hidden"
    />

    <div class="ml-auto hidden flex-row space-x-2 md:flex">
      <Link :href="'/login'">
        <SecondaryButton :class="textColorClass">Login</SecondaryButton>
      </Link>
      <Link :href="'/waitlist'">
        <MainButton>Sign Up</MainButton>
      </Link>
    </div>
  </div>

  <!-- Mobile Navigation -->
  <transition name="slide">
    <div
      v-if="isMobileNavOpen"
      class="fixed right-0 top-0 z-50 h-full w-full bg-[#1f0328] p-4 opacity-95 md:w-1/2"
      style="z-index: 99999"
    >
      <button
        @click="isMobileNavOpen = false"
        class="absolute right-4 top-4 text-2xl text-white"
      >
        <i class="fa-regular fa-circle-xmark text-gray-400"></i>
      </button>
      <div class="mt-20 flex flex-col items-center gap-4 text-white">
        <Link
          :href="'/home'"
          class="!text-white hover:!text-white hover:underline"
        >
          Home
        </Link>
        <div v-for="(link, index) in navLinksForVisitors" :key="index">
          <Link
            :href="link.href"
            class="!text-white hover:!text-white hover:underline"
          >
            {{ link.text }}
          </Link>
        </div>

        <Link :href="'/login'">
          <SecondaryButton class="border-white text-white">
            Login
          </SecondaryButton>
        </Link>
        <Link :href="'/waitlist'">
          <MainButton>Sign Up</MainButton>
        </Link>
      </div>
    </div>
  </transition>
</template>

<style scoped>
  /* Your anchor tag styles */
  /* a {
    color: white !important;
  } */

  /* Mobile Navbar Button */
  .mobile-nav-button {
    @apply p-4;
  }

  /* Slide-in and Slide-out Animations */
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.3s ease-in-out;
  }

  .slide-enter-from {
    transform: translateX(100%);
  }

  .slide-enter-to {
    transform: translateX(0);
  }

  .slide-leave-from {
    transform: translateX(0);
  }

  .slide-leave-to {
    transform: translateX(100%);
  }
</style>
